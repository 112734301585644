$theme-colors: ("primary": #4B4B4B,
    "secondary": #262626,
    "fontcolor":white,
    "main": #FD441D,
    "mycustom":#262626);

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto-bold-webfont.woff') format('woff'),
        url('../fonts/roboto-bold-webfont.ttf') format('truetype'),
        url('../fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoitalic';
    src: url('../fonts/roboto-italic-webfont.eot');
    src: url('../fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto-italic-webfont.woff') format('woff'),
        url('../fonts/roboto-italic-webfont.ttf') format('truetype'),
        url('../fonts/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    src: url("../fonts/roboto-regular-webfont.eot");
    src: url('../fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto-regular-webfont.woff') format('woff'),
        url('../fonts/roboto-regular-webfont.ttf') format('truetype'),
        url('../fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

body,
html {
    font-family: 'Roboto', sans-serif;
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./login.scss";

/* Status Color */
.badge {
    text-transform: capitalize;
    &.processed {
        background-color: #a3d0db81;
    }
    &.queued {
        background-color: #e999bb81;
    }
    &.sent {
        background-color: #3BCB2A81;
    }
    &.opened {
        background-color: #2EA6FF9F;
    }
    &.clicked {
        background-color: #FFFF529F;
    }
    &.bounce {
        background-color: #DF88FF9F;
    }
    &.spam {
        background-color: #bbbb189f;
    }
    &.unsub {
        background-color: #cd8b62;
    }
    &.blocked {
        background-color: #66b4b09f;
    }
    &.softbounced {
        background-color: #D37A009F;
    }
    &.hardbounced {
        background-color: #FF65659F;
    }
    &.deferred {
        background-color: #cd8b62;
    }
}

/* footer */
.footer-navigation {
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        margin: 0 0 0 20px;
        padding: 0 0 0 20px;

        &+li {
            border-left: 1px solid white;
        }
        a {
            color: white !important;
            text-decoration: none !important;
            background-color: transparent !important;
        }
        a:hover {
            text-decoration: underline !important;
        }
    }
}