.main_container {
  width: 100%;
  height: 100vh;
  color: white;
  background: #4B4B4B;
  overflow-x: hidden;
}

/* Header */

.header_container {
  background: #4B4B4B;
}

.header .inner {
  background: #262626 0% 0% no-repeat padding-box;
  padding: 10px 20px;
}

.header h3 {
  padding: 10px 20px;
}

/* filters */

.filter_container {
  background: #4B4B4B;
  color: #FFF;
  padding: 20px 20px;
}
.allFilters {
  border-radius: 5px;
  opacity: 1;
}

/* Contact page */
.contactDetails {
  height: 100vh;
}

.contactDetails tr {
  text-align: left;
}

/* rows per page */
.sc-ikJyIC.gZCsGj option{
  background-color: #4B4B4B;
  color: #fff;
}

/* Reset button */
.reset_button {
  width: 100%;
  border-radius: 10%;
  height: 35px;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transition: 0.2s all;
}

.reset_button:active,
.reset_button:hover {
  transform: scale(0.98);
  border: 1px solid white;
}

/* dropdown menu - react-select */
input[id^='react-select-'] {
  color: #fff !important;
}

.css-tj5bde-Svg {
  fill: #fff !important;
}

/* DateRangePicker */
.rs-picker-default .rs-picker-toggle,
.rs-picker-toggle-textbox {
  background-color: #4B4B4B !important;
  border: none;
}

.rs-picker-default .rs-picker-toggle:hover {
  border: 1px solid white;
}

.rs-picker-toggle-value {
  color: white !important;
}

/* react-data-table-component */
.rdt_TableHeadRow {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}
.sc-furwcr {
  border-bottom: 2px solid white !important;
}
.rdt_TableCell {
  font-size: 15px;
}

.sc-hBUSln  {
  border-bottom: 1px solid black !important;
}
.rdt_TableCol_Sortable {
  size: 10px;
}

.rdt_TableRow:hover{
  background-color: #262626;
  cursor: pointer;
}

/* sorting */
.fa-sort-up {
  opacity: 1 !important;
}
