/* Login page */
.bg {
  background: url('../static/login_background.png') no-repeat;
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.login_label {
  color: rgba(255, 255, 255, 0.7);
}

.login_input {
  background: #4B4B4B;
  border: none;
  height: 50px;
}

.login_input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.login_input:focus {
  background-color: #fff;
}

.submit_btn{
  height: 50px;
}
